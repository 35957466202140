import { computed, ref } from 'vue';
import { useApi } from './useApi';
import { defineStore } from 'pinia';
import { StorageSerializers, useStorage } from '@vueuse/core';
import { config } from '~/config';
import { UsersResponse } from '~/types/pb';
import { selectedCalendarId } from '~/compositions/useCalendarStore';

export const useAuthStore = defineStore('auth', () => {
  const api = useApi();

  const user = ref<UsersResponse | null>(null);
  const token = ref<string | null>(null);

  api.authStore.onChange((_token) => {
    token.value = _token;
    user.value = api.authStore.model as UsersResponse | null;

    // const cookieData = api.authStore.exportToCookie();
    // window.electron?.refreshToken(cookieData);
  }, true);

  const isValid = ref(api.authStore.isValid);
  const isAuthenticated = computed(() => isValid.value && !!token.value && !!user.value);

  function updateIsValid() {
    isValid.value = api.authStore.isValid;
  }

  async function refresh() {
    try {
      updateIsValid();
      // api.authStore.isValid && (await api.collection('users').authRefresh());
    } catch (e) {
      logout();
    }

    if (!api.authStore.isValid) {
      logout();
    }
  }

  async function login(email: string, password: string) {
    await api.collection('users').authWithPassword(email, password);
    updateIsValid();
  }

  function logout() {
    selectedCalendarId.value = null;
    api.authStore.clear();
    updateIsValid();
  }

  const redirectAfterLoginUrl = useStorage<string | null>(
    `${config.localStoragePrefix}redirect-after-login-url`,
    null,
    undefined,
    {
      serializer: StorageSerializers.object,
    },
  );

  return {
    user,
    token,
    isAuthenticated,
    redirectAfterLoginUrl,
    refresh,
    login,
    logout,
  };
});
