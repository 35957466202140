/**
* This file was @generated using pocketbase-typegen
*/

import type PocketBase from 'pocketbase'
import type { RecordService } from 'pocketbase'

export enum Collections {
	Appointments = "appointments",
	CalendarBusinessHours = "calendar_business_hours",
	CalendarMembers = "calendar_members",
	CalendarOwners = "calendar_owners",
	Calendars = "calendars",
	CustomerDuplicates = "customer_duplicates",
	Customers = "customers",
	Notifications = "notifications",
	PhoneCalls = "phone_calls",
	Purposes = "purposes",
	Tasks = "tasks",
	Users = "users",
}

// Alias types for improved usability
export type IsoDateString = string
export type RecordIdString = string
export type HTMLString = string

// System fields
export type BaseSystemFields<T = never> = {
	id: RecordIdString
	created: IsoDateString
	updated: IsoDateString
	collectionId: string
	collectionName: Collections
	expand?: T
}

export type AuthSystemFields<T = never> = {
	email: string
	emailVisibility: boolean
	username: string
	verified: boolean
} & BaseSystemFields<T>

// Record types for each collection

export type AppointmentsRecord = {
	calendar: RecordIdString
	color?: string
	comment?: string
	customer?: RecordIdString
	end: IsoDateString
	purpose?: RecordIdString
	start: IsoDateString
	status?: string
	title?: string
	type: string
}

export type CalendarBusinessHoursRecord = {
	calendar: RecordIdString
	day_of_week: number
	end_time: string
	start_time: string
}

export type CalendarMembersRecord = {
	calendar: RecordIdString
	user: RecordIdString
}

export type CalendarOwnersRecord = {
	avatar?: string
	calendar?: RecordIdString
	city?: string
	email?: string
	info?: string
	latitude?: number
	longitude?: number
	name?: string
	phone?: string
	postalcode?: string
	public?: boolean
	street?: string
	website?: string
}

export type CalendarsRecord = {
	appointment_notifications?: boolean
	appointment_reminder_template?: string
	calendar_day_end_time: string
	calendar_day_start_time: string
	calendar_slot_duration: string
	imported_customers_read_only?: boolean
	language: string
	name: string
	sevenio_token?: string
}

export type CustomerDuplicatesRecord = {
	calendar: RecordIdString
	customer_a: RecordIdString
	customer_b: RecordIdString
	status: string
}

export type CustomersRecord = {
	appointment_notifications?: boolean
	calendar: RecordIdString
	city?: string
	date_of_birth?: IsoDateString
	deleted?: boolean
	email?: string
	first_name: string
	health_insurance?: string
	import_from?: string
	import_id?: string
	last_name: string
	mobile?: string
	phone?: string
	postalcode?: string
	street?: string
}

export type NotificationsRecord<Tcontext = unknown> = {
	calendar: RecordIdString
	context?: null | Tcontext
	has_been_read?: boolean
	hash: string
	type: string
}

export type PhoneCallsRecord = {
	calendar: RecordIdString
	callId: string
	callee?: string
	caller?: string
	connected_at?: IsoDateString
	direction?: string
	ended_at?: IsoDateString
	extension?: string
	started_at?: IsoDateString
	status?: string
}

export type PurposesRecord = {
	bookable?: boolean
	calendar: RecordIdString
	color?: string
	deleted?: boolean
	name: string
}

export type TasksRecord = {
	appointment: RecordIdString
	calendar: RecordIdString
	execute_at: IsoDateString
	status: string
	type: string
}

export type UsersRecord = {
	avatar?: string
	name?: string
}

// Response types include system fields and match responses from the PocketBase API
export type AppointmentsResponse<Texpand = unknown> = Required<AppointmentsRecord> & BaseSystemFields<Texpand>
export type CalendarBusinessHoursResponse<Texpand = unknown> = Required<CalendarBusinessHoursRecord> & BaseSystemFields<Texpand>
export type CalendarMembersResponse<Texpand = unknown> = Required<CalendarMembersRecord> & BaseSystemFields<Texpand>
export type CalendarOwnersResponse<Texpand = unknown> = Required<CalendarOwnersRecord> & BaseSystemFields<Texpand>
export type CalendarsResponse<Texpand = unknown> = Required<CalendarsRecord> & BaseSystemFields<Texpand>
export type CustomerDuplicatesResponse<Texpand = unknown> = Required<CustomerDuplicatesRecord> & BaseSystemFields<Texpand>
export type CustomersResponse<Texpand = unknown> = Required<CustomersRecord> & BaseSystemFields<Texpand>
export type NotificationsResponse<Tcontext = unknown, Texpand = unknown> = Required<NotificationsRecord<Tcontext>> & BaseSystemFields<Texpand>
export type PhoneCallsResponse<Texpand = unknown> = Required<PhoneCallsRecord> & BaseSystemFields<Texpand>
export type PurposesResponse<Texpand = unknown> = Required<PurposesRecord> & BaseSystemFields<Texpand>
export type TasksResponse<Texpand = unknown> = Required<TasksRecord> & BaseSystemFields<Texpand>
export type UsersResponse<Texpand = unknown> = Required<UsersRecord> & AuthSystemFields<Texpand>

// Types containing all Records and Responses, useful for creating typing helper functions

export type CollectionRecords = {
	appointments: AppointmentsRecord
	calendar_business_hours: CalendarBusinessHoursRecord
	calendar_members: CalendarMembersRecord
	calendar_owners: CalendarOwnersRecord
	calendars: CalendarsRecord
	customer_duplicates: CustomerDuplicatesRecord
	customers: CustomersRecord
	notifications: NotificationsRecord
	phone_calls: PhoneCallsRecord
	purposes: PurposesRecord
	tasks: TasksRecord
	users: UsersRecord
}

export type CollectionResponses = {
	appointments: AppointmentsResponse
	calendar_business_hours: CalendarBusinessHoursResponse
	calendar_members: CalendarMembersResponse
	calendar_owners: CalendarOwnersResponse
	calendars: CalendarsResponse
	customer_duplicates: CustomerDuplicatesResponse
	customers: CustomersResponse
	notifications: NotificationsResponse
	phone_calls: PhoneCallsResponse
	purposes: PurposesResponse
	tasks: TasksResponse
	users: UsersResponse
}

// Type for usage with type asserted PocketBase instance
// https://github.com/pocketbase/js-sdk#specify-typescript-definitions

export type TypedPocketBase = PocketBase & {
	collection(idOrName: 'appointments'): RecordService<AppointmentsResponse>
	collection(idOrName: 'calendar_business_hours'): RecordService<CalendarBusinessHoursResponse>
	collection(idOrName: 'calendar_members'): RecordService<CalendarMembersResponse>
	collection(idOrName: 'calendar_owners'): RecordService<CalendarOwnersResponse>
	collection(idOrName: 'calendars'): RecordService<CalendarsResponse>
	collection(idOrName: 'customer_duplicates'): RecordService<CustomerDuplicatesResponse>
	collection(idOrName: 'customers'): RecordService<CustomersResponse>
	collection(idOrName: 'notifications'): RecordService<NotificationsResponse>
	collection(idOrName: 'phone_calls'): RecordService<PhoneCallsResponse>
	collection(idOrName: 'purposes'): RecordService<PurposesResponse>
	collection(idOrName: 'tasks'): RecordService<TasksResponse>
	collection(idOrName: 'users'): RecordService<UsersResponse>
}
