import PocketBase, { RecordListOptions } from 'pocketbase';
import { config } from '~/config';
import { RecordModel } from 'pocketbase';
import { CollectionRecords, CollectionResponses, Collections, TypedPocketBase } from '~/types/pb';
import { useRecordStore } from './useRecordStore';
import { computed, onBeforeUnmount, Ref, ref, watch } from 'vue';

const client = new PocketBase(config.apiUrl || undefined) as TypedPocketBase;
client.autoCancellation(false);

type Store<T extends keyof CollectionRecords> = ReturnType<typeof useRecordStore<CollectionRecords[T] & RecordModel>>;
const recordsStores: { [Key in keyof CollectionRecords]?: Store<Key> } = {};

export function useApi() {
  if (Object.values(recordsStores).length === 0) {
    for (const collection of Object.values(Collections)) {
      recordsStores[collection] = useRecordStore(client, collection) as Store<any>;
    }
  }

  function col<T extends keyof CollectionResponses, S = CollectionResponses[T]>(collection: T) {
    const store = recordsStores[collection] as Store<T>;
    const c = client.collection(collection);
    return {
      create: c.create.bind(c)<S>,
      update: c.update.bind(c)<S>,
      delete: c.delete.bind(c),
      getOne: store.getOne,
      getList: store.getList,
      getFirstFromList: store.getFirstFromList,
    };
  }

  return Object.assign(client, { col });
}

export function useCollection<T extends keyof CollectionResponses, S = CollectionResponses[T]>(
  collection: T,
  options?: {
    filter?: Ref<string>;
    page?: Ref<number>;
    perPage?: Ref<number>;
    sort?: Ref<string>;
    subscribe?: boolean;
  },
): {
  items: Ref<S[] | undefined>;
  loading: Ref<boolean>;
  total: Ref<number>;
  refresh: () => Promise<void>;
} {
  const api = useApi();

  const items = ref<S[]>();
  const loading = ref(false);
  const total = ref(0);
  const page = computed(() => options?.page?.value ?? 1);
  const perPage = computed(() => options?.perPage?.value ?? 10);
  const filter = computed(() => options?.filter?.value);
  const sort = computed(() => options?.sort?.value);

  async function load() {
    loading.value = true;

    const listOptions: RecordListOptions = {};
    if (filter.value) {
      listOptions.filter = filter.value;
    }
    if (sort.value) {
      listOptions.sort = sort.value;
    }
    listOptions.perPage = options?.perPage?.value ?? 10;

    const response = await api.collection(collection).getList<S>(page.value, perPage.value, listOptions);
    items.value = response.items;
    total.value = response.totalItems;

    loading.value = false;
  }

  // if (options?.subscribe) {
  //   const subscription = api.collection(collection).subscribe(
  //     '*',
  //     (e) => {
  //       if (e.action === 'create') {
  //         items.value?.push(e.record as S);
  //         total.value += 1;
  //       } else if (e.action === 'update') {
  //         let found = false;
  //         items.value = items.value?.map((item) => {
  //           if ((item as any)._id === (e.record as any)._id) {
  //             found = true;
  //             return e.record as S;
  //           }
  //           return item;
  //         });
  //         if (!found) {
  //           items.value?.push(e.record as S);
  //           total.value += 1;
  //         }
  //       } else {
  //         items.value = items.value?.filter((item) => (item as any)._id !== (e.record as any)._id);
  //         total.value -= 1;
  //       }
  //     },
  //     {
  //       filter: options?.filter?.value,
  //     },
  //   );

  //   onBeforeUnmount(async () => {
  //     (await subscription)();
  //   });
  // }

  const watchHandle = watch([filter, page, sort, perPage], load, { immediate: true });
  onBeforeUnmount(() => {
    watchHandle.stop();
  });

  return {
    items,
    loading,
    total,
    refresh: load,
  };
}
