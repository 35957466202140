// https://github.com/abdullah2993/expression-parser/blob/master/src/evaluator.ts

import { Expression, parse, BinaryExpression } from './parser';
import { IdentifierToken, NumberToken, Scanner, TextToken, Token } from './scanner';

// function evaluateFunctionCall(name: string, args: any[]): any {
//   switch (name) {
//     case 'length':
//       if (args.length !== 1) {
//         throw new Error('Length function takes exactly one argument.');
//       }
//       return args[0].length;
//     default:
//       throw new Error(`Function ${name} not implemented`);
//   }
// }

function evaluateBinaryExpression(expression: BinaryExpression, context?: (identifier: string) => any): any {
  const left = evaluateExpression(expression.left, context);
  const right = evaluateExpression(expression.right, context);
  switch (expression.operator) {
    case '=':
      return left === right;
    case '!=':
      return left !== right;
    case '~':
      return (left as string)
        .toString()
        .toLowerCase()
        .includes((right as string).toString().toLowerCase());
    case '!~':
      return !(left as string)
        .toString()
        .toLowerCase()
        .includes((right as string).toString().toLowerCase());
    case '>':
      return left > right;
    case '>=':
      return left >= right;
    case '<':
      return left < right;
    case '<=':
      return left <= right;
    case '&&':
      return left && right;
    case '||':
      return left || right;
    default:
      throw new Error(`Operator ${expression.operator} not implemented`);
  }
}

function evaluateExpression(expression: Expression, context?: (identifier: string) => any): any {
  switch (expression.type) {
    case 'IdentifierExpression':
      const token = expression.value as IdentifierToken;
      if (!context) {
        throw new Error('Context not provided');
      }
      return context(token.literal);
    case 'ValueExpression':
      const _token = expression.value as NumberToken | TextToken;
      if (_token.type === 'NUMBER') {
        return Number(_token.literal);
      }

      return _token.literal;
    // case 'FunctionCallExpression':
    //   return evaluateFunctionCall(expression.name, evaluateExpressions(expression.args, context));
    case 'BinaryExpression':
      return evaluateBinaryExpression(expression, context);
    default:
      throw new Error(`Invalid AST node${expression}`);
  }
}

export function evaluate(input: string, context?: (key: string) => any): any {
  const scanner = new Scanner(input);
  const tokens = scanner.scanAll();
  const expression = parse(tokens);
  return evaluateExpression(expression, context);
}
