import { createPinia } from 'pinia';
import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import * as Sentry from '@sentry/vue';
import Aura from '@primevue/themes/aura';

import 'primeicons/primeicons.css';
import '~/assets/theme.css';

import App from '~/App.vue';
import useRouter from '~/router';
import '~/lib/dayjs';
import { definePreset } from '@primevue/themes';

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);

const router = useRouter();

Sentry.init({
  app,
  dsn: 'https://YB3sU2o9HKJAkdjY6wYQMdzfle6Ew49J@bugslide.vercel.app/4',
  environment: import.meta.env.NODE_ENV ?? 'development',
  release: import.meta.env.GIT_COMMIT,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  logErrors: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
});

const MyPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
      950: '{blue.950}',
    },
    // primary: {
    //   50: '{zinc.50}',
    //   100: '{zinc.100}',
    //   200: '{zinc.200}',
    //   300: '{zinc.300}',
    //   400: '{zinc.400}',
    //   500: '{zinc.500}',
    //   600: '{zinc.600}',
    //   700: '{zinc.700}',
    //   800: '{zinc.800}',
    //   900: '{zinc.900}',
    //   950: '{zinc.950}',
    // },
    // colorScheme: {
    //   light: {
    //     primary: {
    //       color: '{zinc.600}',
    //       inverseColor: '#ffffff',
    //       hoverColor: '{zinc.700}',
    //       activeColor: '{zinc.800}',
    //     },
    //     highlight: {
    //       background: '{zinc.600}',
    //       focusBackground: '{zinc.700}',
    //       color: '#ffffff',
    //       focusColor: '#ffffff',
    //     },
    //   },
    //   dark: {
    //     primary: {
    //       color: '{zinc.50}',
    //       inverseColor: '{zinc.950}',
    //       hoverColor: '{zinc.100}',
    //       activeColor: '{zinc.200}',
    //     },
    //     highlight: {
    //       background: 'rgba(250, 250, 250, .16)',
    //       focusBackground: 'rgba(250, 250, 250, .24)',
    //       color: 'rgba(255,255,255,.87)',
    //       focusColor: 'rgba(255,255,255,.87)',
    //     },
    //   },
    // },
  },
});

app.use(PrimeVue, {
  theme: {
    preset: MyPreset,
    options: {
      darkModeSelector: '.dark',
    },
  },
});
app.use(router);
app.mount('#app');
