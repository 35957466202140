export type TokenTypes =
  | 'UNKNOWN'
  | 'EOF'
  | 'WHITESPACE'
  | 'GROUP'
  | 'IDENTIFIER'
  | 'NUMBER'
  | 'TEXT'
  | 'OPERATOR'
  | 'JOIN';

export type UnknownToken = {
  type: 'UNKNOWN';
  literal: string;
};

export type EOFToken = {
  type: 'EOF';
};

export type WhitespaceToken = {
  type: 'WHITESPACE';
  literal: string;
};

export type GroupToken = {
  type: 'GROUP';
  literal: string;
  children: Token[];
};

export type IdentifierToken = {
  type: 'IDENTIFIER';
  literal: string;
};

export type NumberToken = {
  type: 'NUMBER';
  literal: string;
};

export type TextToken = {
  type: 'TEXT';
  literal: string;
};

export type OperatorToken = {
  type: 'OPERATOR';
  literal: string;
};

export type JoinToken = {
  type: 'JOIN';
  literal: string;
};

export type Token = (
  | UnknownToken
  | EOFToken
  | WhitespaceToken
  | GroupToken
  | IdentifierToken
  | NumberToken
  | TextToken
  | OperatorToken
  | JoinToken
) & { error?: Error };

class StringScanner {
  input: string;
  position: number;

  constructor(input: string) {
    this.input = input;
    this.position = 0;
  }

  readChar(): string | null {
    if (this.position >= this.input.length) {
      return null;
    }

    const ch = this.input[this.position];

    this.position += 1;

    return ch;
  }

  unreadChar(): void {
    this.position -= 1;
  }
}

type TokenType = {
  name: TokenTypes;
  start: (ch: string) => boolean;
  scan: (scanner: StringScanner) => { error?: Error; literal?: string };
};

const operator = ['=', '!=', '~', '!~', '<', '<=', '>', '>='];

const tokenTypes: TokenType[] = [
  {
    name: 'WHITESPACE',
    start: (ch: string) => ch === ' ',
    scan: (scanner: StringScanner) => {
      let literal = '';

      while (true) {
        const ch = scanner.readChar();

        if (ch === null) {
          break;
        }

        if (ch !== ' ') {
          scanner.unreadChar();
          break;
        }

        literal += ch;
      }

      return { literal };
    },
  },
  {
    name: 'GROUP',
    start: (ch: string) => ch === '(',
    scan: (scanner: StringScanner) => {
      let literal = '';

      while (true) {
        const ch = scanner.readChar();

        if (ch === null) {
          break;
        }

        if (ch === ')') {
          literal += ch;
          break;
        }

        literal += ch;
      }

      if (literal.at(-1) !== ')') {
        return { error: new Error('Empty group') };
      }

      return { literal };
    },
  },
  {
    name: 'IDENTIFIER',
    start: (ch: string) => (ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z') || ch == '_' || ch == '@' || ch == '#',
    scan: (scanner: StringScanner) => {
      let literal = '';

      while (true) {
        const ch = scanner.readChar();

        if (ch === null) {
          break;
        }

        if (
          !((ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z')) &&
          ch !== '_' &&
          ch !== '@' &&
          ch !== '#' &&
          ch !== '.' &&
          !(ch >= '0' && ch <= '9')
        ) {
          scanner.unreadChar();
          break;
        }

        literal += ch;
      }

      return { literal };
    },
  },
  {
    name: 'NUMBER',
    start: (ch: string) => ch >= '0' && ch <= '9',
    scan: (scanner: StringScanner) => {
      let literal = '';

      while (true) {
        const ch = scanner.readChar();

        if (ch === null) {
          break;
        }

        if (ch < '0' || ch > '9') {
          scanner.unreadChar();
          break;
        }

        literal += ch;
      }

      return { literal };
    },
  },
  {
    name: 'TEXT',
    start: (ch: string) => ch === '"' || ch === "'",
    scan: (scanner: StringScanner) => {
      let literal = '';

      while (true) {
        const ch = scanner.readChar();

        if (ch === null) {
          break;
        }

        if (literal.length > 0 && (ch === '"' || ch === "'")) {
          literal += ch;
          break;
        }

        literal += ch;
      }

      if ((literal[0] === '"' && literal.at(-1) !== '"') || (literal[0] === "'" && literal.at(-1) !== "'")) {
        return { error: new Error('Unclosed text literal') };
      }

      return { literal: literal.slice(1, -1) };
    },
  },
  {
    name: 'OPERATOR',
    start: (ch: string) => ch === '=' || ch === '!' || ch === '<' || ch === '>' || ch === '~',
    scan: (scanner: StringScanner) => {
      let literal = '';

      while (true) {
        const ch = scanner.readChar();

        if (ch === null) {
          break;
        }

        if (!(ch === '=' || ch === '!' || ch === '<' || ch === '>' || ch === '~')) {
          scanner.unreadChar();
          break;
        }

        literal += ch;
      }

      if (!operator.includes(literal)) {
        return { error: new Error(`Invalid operator "${literal}"`) };
      }

      return { literal };
    },
  },
  {
    name: 'JOIN',
    start: (ch: string) => ch === '&' || ch === '|',
    scan: (scanner: StringScanner) => {
      let literal = '';

      while (true) {
        const ch = scanner.readChar();

        if (ch === null) {
          break;
        }

        if (ch !== '&' && ch !== '|') {
          scanner.unreadChar();
          break;
        }

        literal += ch;
      }

      if (literal !== '&&' && literal !== '||') {
        return { error: new Error(`Invalid join operator ${literal}`) };
      }

      return { literal };
    },
  },
];

export class Scanner {
  input: StringScanner;

  constructor(input: string) {
    this.input = new StringScanner(input);
  }

  scan(): Token {
    const ch = this.input.readChar();

    if (ch === null) {
      return { type: 'EOF' };
    }

    for (const tokenType of tokenTypes) {
      if (tokenType.start(ch)) {
        this.input.unreadChar();

        const scan = tokenType.scan(this.input);

        return { ...scan, type: tokenType.name };
      }
    }

    return {
      type: 'UNKNOWN',
      literal: ch,
    };
  }

  scanAll(): Token[] {
    const tokens: Token[] = [];

    while (true) {
      const token = this.scan();

      if (token.type === 'EOF') {
        break;
      }

      if (token.type === 'WHITESPACE') {
        continue;
      }

      if (token.type === 'GROUP') {
        const scanner = new Scanner(token.literal.slice(1, -1));
        token.children = scanner.scanAll();
      }

      tokens.push(token);
    }

    return tokens;
  }
}
