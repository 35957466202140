import { Scanner, Token, TokenTypes } from './scanner';

type BinaryOperator = '&&' | '||' | '=' | '!=' | '~' | '!~' | '<' | '<=' | '>' | '>=';

export type BinaryExpression = {
  type: 'BinaryExpression';
  operator: BinaryOperator;
  left: Expression;
  right: Expression;
};

export type UnaryExpression = {
  type: 'UnaryExpression';
  operator: string;
  argument: Expression;
};

export type ValueExpression = {
  type: 'ValueExpression';
  value: Token;
};

export type IdentifierExpression = {
  type: 'IdentifierExpression';
  value: Token;
};

export type Expression = BinaryExpression | UnaryExpression | ValueExpression | IdentifierExpression;

const precedences: Record<string, number> = {
  '&&': 1,
  '||': 1,
  '=': 2,
  '!=': 2,
  '~': 2,
  '!~': 2,
  '<': 2,
  '<=': 2,
  '>': 2,
  '>=': 2,
};

class Parser {
  tokens: Token[];
  position: number;

  constructor(tokens: Token[]) {
    this.tokens = tokens;
    this.position = 0;
  }

  parse(): Expression {
    const expr = this.parseExpression();

    if (this.position < this.tokens.length) {
      console.log(this.position, this.tokens.length, this.tokens.at(-1));
      throw new Error('Unexpected token');
    }

    return expr;
  }

  parseExpression(precedence = 0): Expression {
    let expr = this.parsePrimary();

    while (this.position < this.tokens.length) {
      const token = this.tokens[this.position];

      if (token.type !== 'OPERATOR' && token.type !== 'JOIN') {
        break;
      }

      const opPrecedence = precedences[token.literal];

      if (opPrecedence <= precedence) {
        break;
      }

      this.position += 1;

      const right = this.parseExpression(opPrecedence);

      expr = {
        type: 'BinaryExpression',
        operator: token.literal as BinaryOperator,
        left: expr,
        right,
      };
    }

    return expr;
  }

  parsePrimary(): Expression {
    const token = this.tokens[this.position];

    if (token.type === 'NUMBER' || token.type === 'TEXT') {
      this.position += 1;

      return {
        type: 'ValueExpression',
        value: token,
      };
    }

    if (token.type === 'IDENTIFIER') {
      this.position += 1;

      return {
        type: 'IdentifierExpression',
        value: token,
      };
    }

    if (token.type === 'GROUP') {
      this.position += 1;

      return new Parser(token.children).parse();
    }

    throw new Error('Unexpected token');
  }
}

export function parse(tokens: Token[]): Expression {
  const parser = new Parser(tokens);

  return parser.parse();
}
