import { Component } from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { useAuthStore } from '~/compositions/useAuthStore';
import { useCalendarStore } from './compositions/useCalendarStore';

declare module 'vue-router' {
  interface RouteMeta {
    authentication?: 'ignored' | 'required' | 'unauthenticated-only';
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/calendar/:calendarId',
    component: (): Component => import('~/components/calendar/CalendarWrapper.vue'),
    meta: { calendar: true },
    children: [
      {
        path: '',
        name: 'calendar',
        component: (): Component => import('~/views/calendars/Calendar.vue'),
      },
      {
        path: 'customers',
        name: 'customers',
        component: (): Component => import('~/views/customers/Customers.vue'),
      },
      {
        path: 'customers/duplicates',
        name: 'customer-duplicates',
        component: (): Component => import('~/views/customers/CustomerDuplicates.vue'),
      },
      {
        path: 'customers/:customerId',
        name: 'customer',
        props: true,
        component: (): Component => import('~/views/customers/Customer.vue'),
      },
      {
        path: 'customers/:customerId/log',
        name: 'customer-log',
        props: true,
        component: (): Component => import('~/views/customers/Customer.vue'),
      },
      {
        path: 'customers/create',
        name: 'customer-create',
        component: (): Component => import('~/views/customers/CustomerCreate.vue'),
      },
      {
        path: 'appointments/:appointmentId',
        name: 'appointment',
        props: true,
        component: (): Component => import('~/views/appointments/Appointment.vue'),
      },
      {
        path: 'appointments/create/start/:start/end/:end',
        name: 'appointment-create',
        props: true,
        component: (): Component => import('~/views/appointments/AppointmentCreate.vue'),
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: (): Component => import('~/views/Notifications.vue'),
      },
      {
        path: 'settings',
        component: (): Component => import('~/components/settings/SettingsWrapper.vue'),
        children: [
          {
            path: '',
            redirect: { name: 'settings-general' },
            name: 'settings',
          },
          {
            path: 'general',
            name: 'settings-general',
            component: (): Component => import('~/views/settings/General.vue'),
          },
          {
            path: 'office',
            name: 'settings-office',
            component: (): Component => import('~/views/settings/Office.vue'),
          },
          {
            path: 'calendar',
            component: (): Component => import('~/components/layout/RouterView.vue'),
            children: [
              {
                path: '',
                name: 'settings-calendar',
                component: (): Component => import('~/views/settings/Calendar.vue'),
              },
              {
                path: 'opening-hours',
                name: 'settings-opening-hours',
                component: (): Component => import('~/views/settings/OpeningHours.vue'),
              },
            ],
          },
          {
            path: 'purpose',
            component: (): Component => import('~/components/layout/RouterView.vue'),
            children: [
              {
                path: '',
                name: 'settings-purposes',
                component: (): Component => import('~/views/settings/purpose/Purposes.vue'),
              },
              {
                path: 'create',
                name: 'settings-purpose-create',
                component: (): Component => import('~/views/settings/purpose/PurposeCreate.vue'),
              },
              {
                path: ':purposeId',
                name: 'settings-purpose',
                component: (): Component => import('~/views/settings/purpose/Purpose.vue'),
                props: true,
              },
            ],
          },
          {
            path: 'api',
            name: 'settings-api',
            component: (): Component => import('~/views/settings/Api.vue'),
          },
          {
            path: 'desktop',
            name: 'settings-desktop',
            component: (): Component => import('~/views/settings/Desktop.vue'),
          },
          {
            path: 'members',
            name: 'settings-members',
            component: (): Component => import('~/views/settings/Members.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/',
    name: 'home',
    component: (): Component => import('~/views/Home.vue'),
  },
  {
    path: '/calendar/create',
    name: 'calendar-create',
    component: (): Component => import('~/views/calendars/CreateCalendar.vue'),
  },
  {
    path: '/auth/login',
    name: 'auth-login',
    meta: { authentication: 'unauthenticated-only' },
    component: (): Component => import('~/views/auth/Login.vue'),
  },
  {
    path: '/auth/register',
    name: 'auth-register',
    meta: { authentication: 'unauthenticated-only' },
    component: (): Component => import('~/views/auth/Register.vue'),
  },
  {
    path: '/auth/reset-password',
    name: 'auth-reset-password',
    meta: { authentication: 'unauthenticated-only' },
    component: (): Component => import('~/views/auth/ResetPassword.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    meta: { authentication: 'ignored' },
    component: (): Component => import('~/views/NotFound.vue'),
  },
];

export default function () {
  const authStore = useAuthStore();
  const calendarStore = useCalendarStore();

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  // auth middleware
  router.beforeEach(async (to, _from) => {
    await authStore.refresh();

    // do not load authentication in callback
    if (to.name === 'auth-callback') {
      return;
    }

    const authentication = to.meta.authentication || 'required';

    if (authentication === 'required' && !authStore.isAuthenticated) {
      authStore.redirectAfterLoginUrl = to.fullPath;
      return { name: 'auth-login' };
    }

    if (authentication === 'unauthenticated-only' && authStore.isAuthenticated) {
      // user is already authenticated, to prevent unnecessary authentication redirect to home
      return { name: 'home' };
    }

    const calendarId = calendarStore.selectedCalendarId;
    if (to.name === 'home' && calendarId) {
      return { name: 'calendar', params: { calendarId } };
    }

    if (to.matched.some((r) => r.meta.calendar) && (calendarId === undefined || to.params.calendarId !== calendarId)) {
      calendarStore.selectedCalendarId = to.params.calendarId as string;
    }

    return true;
  });

  return router;
}
